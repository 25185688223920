import { gsap } from "gsap"

document.addEventListener("DOMContentLoaded", function() {
    console.log(
        `%cCustomer Support website%c development by %cdmytro mamon.`,
        "color: #FFFFFF; background: #5289FF; padding: 2px 4px; border-radius: 4px;",
        "",
        "color: #5289FF; font-weight: bold;"
    );
});

const accordionTitles = document.querySelectorAll('[data-accordion-title]');
accordionTitles.forEach((title, i) => {
    title.addEventListener('click', () => {
        const containers = document.querySelectorAll('[data-accordion-container]');
        accordionTitles.forEach((titleDisabled, i) => {
            if (titleDisabled == title) return
            titleDisabled.classList.remove('bg-black', 'text-white');
            titleDisabled.classList.add('text-copy');
        });
        title.classList.add('bg-black', 'text-white');
        containers.forEach(container => {
            if (container == containers[i]) return
            gsap.to(container, {
                y: 20,
                opacity: 0,
                onComplete: () => {
                    container.classList.add('md:hidden');
                    gsap.set(containers[i], {
                        y: -20,
                        opacity: 0
                    });
                    containers[i].classList.remove('md:hidden');
                    gsap.to(containers[i], {
                        y: 0,
                        opacity: 1
                    });
                }
            });
        });
    });
});

const overlay = document.querySelector('#overlay');

const menuButton = document.querySelector('#menuButton');
const menuCloseButton = document.querySelector('#menuCloseButton');
const navigation = document.querySelector('#navigation');

if (menuButton) {
    // console.log("setup Menu Button");
    menuButton.addEventListener('click', function(e){
        e.preventDefault();
        gsap.to(navigation, {
            x: 0,
            xPercent: 0,
            ease: "power3.inOut",
            duration: .6
        });
        disableScroll();
    });
};

if (menuCloseButton) {
    // console.log("setup Menu Close Button");
    menuCloseButton.addEventListener('click', function(e){
        e.preventDefault();
        gsap.to(navigation, {
            x: 0,
            xPercent: 100,
            ease: "power3.inOut",
            duration: .45
        });
        enableScroll();
    });
};

if (overlay) {
    // console.log("setup Overlay");
    overlay.addEventListener('click', function(e){
        gsap.to(navigation, {
            x: 0,
            xPercent: 100,
            ease: "power3.inOut",
            duration: .45
        });
        enableScroll();
    });
};

// call this to Disable
function disableScroll() {
    document.body.classList.add('overflow-hidden');
    overlay.classList.add('bg-black/50');
    overlay.classList.remove('pointer-events-none');
};

// call this to Enable
function enableScroll() {
    document.body.classList.remove('overflow-hidden');
    overlay.classList.remove('bg-black/50');
    overlay.classList.add('pointer-events-none');
};